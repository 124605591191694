var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('account-role-title',{attrs:{"role":"Buyer"}}),_c('span',{staticClass:"title d-block mb-2"},[_vm._v(_vm._s(_vm.$t("Transactions")))]),_c('v-data-table',{staticClass:"unset-table-hover v-orders",attrs:{"headers":_vm.headers,"items":_vm.transactions,"options":_vm.options,"server-items-length":_vm.totalTransactions,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': _vm.$store.getters['nav/itemsPerPageOptions'],
        'disable-pagination': _vm.loading,
        'disable-items-per-page': _vm.loading,
      },"mobile-breakpoint":100,"items-per-page":_vm.globalItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.updateItemsPerPage,"update:page":_vm.getTransactionsFromApi,"update:sort-desc":_vm.getTransactionsFromApi},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticStyle:{"width":"90vw","max-width":"1390px"},attrs:{"type":"table-row-divider@5"}})]},proxy:true},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.typeColor[item.type]) + "--text"),attrs:{"small":"","color":_vm.typeColor[item.type]}},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.object",fn:function(ref){
      var item = ref.item;
return [(item.type == 'Invoice')?_c('span',[_vm._v(_vm._s(_vm.$t('Account top up')))]):(item.type == 'Order')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Ordered Item'))+" "),_c('a',{on:{"click":function($event){return _vm.$router.push({name:'order',params:{id:item.object.id}})}}},[_vm._v(_vm._s(item.object.id))]),_vm._v(" "+_vm._s(_vm.$t('from'))+" "),_c('a',{on:{"click":function($event){return _vm.$router.push({name:'store-products',params:{slug:item.object.store_slug}})}}},[_vm._v(_vm._s(item.object.store_title))])]):(item.type == 'Refund')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Refund Item'))+" "),_c('a',{on:{"click":function($event){return _vm.$router.push({name:'order',params:{id:item.object.id}})}}},[_vm._v(_vm._s(item.object.id))]),_vm._v(" "+_vm._s(_vm.$t('from'))+" "),_c('a',{on:{"click":function($event){return _vm.$router.push({name:'store-products',params:{slug:item.object.store_slug}})}}},[_vm._v(_vm._s(item.object.store_title))])]):_vm._e()]}},{key:"item.debit",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.debit ? ("-$" + (Number(item.debit))) : ("$" + (Number(item.credit))))+" ")]}},{key:"item.balance",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(("$" + (Number(item.balance))))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }