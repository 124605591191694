<template>
<div>
  <account-role-title role="Buyer" />
  <!-- <breadcrumbs /> -->
  <span class="title d-block mb-2">{{ $t("Transactions") }}</span>
  <v-data-table
      :headers="headers"
      :items="transactions"
      :options.sync="options"
      :server-items-length="totalTransactions"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': $store.getters['nav/itemsPerPageOptions'],
        'disable-pagination': loading,
        'disable-items-per-page': loading,
      }"
      :mobile-breakpoint="100"
      class="unset-table-hover v-orders"
      @update:items-per-page="updateItemsPerPage"
      @update:page="getTransactionsFromApi"
      @update:sort-desc="getTransactionsFromApi"
      :items-per-page="globalItemsPerPage"
  >
    <template v-slot:loading>
      <v-skeleton-loader type="table-row-divider@5" style="width: 90vw; max-width: 1390px"></v-skeleton-loader>
    </template>
    <template #[`item.type`]="{item}">
      <v-chip
          small
          :color="typeColor[item.type]"
          :class="`${typeColor[item.type]}--text`"
          class="v-chip-light-bg"
      >
        {{ item.type }}
      </v-chip>
    </template>
    <template #[`item.object`]="{item}">
      <span v-if="item.type == 'Invoice'">{{ $t('Account top up') }}</span>
      <span v-else-if="item.type == 'Order'">
        {{ $t('Ordered Item') }}
        <a @click="$router.push({name:'order',params:{id:item.object.id}})">{{ item.object.id }}</a>
        {{ $t('from') }}
        <a @click="$router.push({name:'store-products',params:{slug:item.object.store_slug}})">{{ item.object.store_title }}</a>
      </span>
      <span v-else-if="item.type == 'Refund'">
        {{ $t('Refund Item') }}
        <a @click="$router.push({name:'order',params:{id:item.object.id}})">{{ item.object.id }}</a>
        {{ $t('from') }}
        <a @click="$router.push({name:'store-products',params:{slug:item.object.store_slug}})">{{ item.object.store_title }}</a>
      </span>
    </template>
    <template #[`item.debit`]="{item}">
      {{ item.debit ? `-$${Number(item.debit)}` : `$${Number(item.credit)}` }}
    </template>
    <template #[`item.balance`]="{item}">
      {{ `$${Number(item.balance)}` }}
    </template>
  </v-data-table>
</div>
</template>

<script>
import axios from "@axios"
import {useRouter} from "@core/utils"
import {ref, watch, computed} from "@vue/composition-api/dist/vue-composition-api"
import {truncate} from "@/plugins/helpers"
import store from '@/store'

export default {
  name: "Transactions",
  setup() {
    const {route, router} = useRouter()
    const options = computed(({
      get() {
        return store.state.pagination.transactions;
      },
      set(val) {
        let page = val.page > val.lastPage ? 1 : val.page
        store.commit("pagination/SET_TRANSACTIONS_PAGINATION", Object.assign({}, val, {
          page
        }))
      }
    }))
    const loading = ref(true)
    const transactions = ref([])
    const totalTransactions = ref(0)
    const getTransactionsFromApi = () => {
      loading.value = true
      const {groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = options.value
      const URL = route.value.name == 'admin-transactions-user-list' ? 'admin/transactions/' + route.value.params.user_id : 'transactions'
      axios.get(URL, {
        params: {
          pagination: globalItemsPerPage.value,
          page,
          sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
          sort_dir: sortDesc && sortDesc[0] ? 'ASC' : 'DESC',
        }
      }).then(res => {
        transactions.value = res.data.data
        totalTransactions.value = res.data.meta.total
        store.commit("pagination/SET_TRANSACTIONS_PAGINATION", { itemsPerPage: globalItemsPerPage.value, page, lastPage: res.data.meta.last_page })
        loading.value = false
      }).catch(e => console.log(e))
    }

    const globalItemsPerPage = computed(() => store.getters['nav/globalItemPerPage'])
    const updateItemsPerPage = (e) => {
      store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e);
      getTransactionsFromApi()
    }

    getTransactionsFromApi()

    return {
      updateItemsPerPage,
      getTransactionsFromApi,
      globalItemsPerPage,
      options,
      loading,
      transactions,
      totalTransactions,
      truncate,
      typeColor: {
        Invoice: 'primary',
        Order: 'warning',
        Refund: 'error'
      },
      headers: [
        {text: 'Id', value: 'id', width: 340},
        {text: 'Date', value: 'post_date', width: 115},
        {text: 'Type', value: 'type', width: 115, sortable: false},
        {text: 'Description', value: 'object', sortable: false},
        {text: 'Amount', value: 'debit', sortable: false, width: 90},
        {text: 'Balance', value: 'balance', sortable: false},
      ],
    }
  }
}
</script>
